<template>
  <div class="calendar" :class="{ 'calendar--active': isActive }" :style="{ minWidth: minWidthComputed }">
    <Button ref="calendar__button" :class="{ active: isActive }" @clicked="show = true">
      <Icon icon="calendar" class="mr-2 calendar__icon" />

      <template v-if="range.start && range.end">
        <span>{{ range.start | moment("Do MMM YYYY") }}</span>
        <span>-</span>
        <span>{{ range.end | moment("Do MMM YYYY") }}</span>
      </template>

      <span v-else class="paragraph">All time</span>
    </Button>

    <Button class="calendar__reset" @clicked="resetDates">
      <Icon icon="close" :width="14" :height="14" />
    </Button>

    <DatePicker
      v-if="show"
      ref="calendar__picker"
      v-model="range"
      v-closable="{
        handler: 'hideDatePicker',
        exclude: ['calendar__button', 'calendar__picker'],
      }"
      mode="range"
      is-range
      :attributes="attributes"
      class="calendar__picker"
      is-dark
      @input="emitDates"
    />
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import { Icon } from "cavea-design-system";

export default {
  name: "Calendar",

  components: {
    DatePicker,
    Icon,
    Button: () => import("@/components/Button"),
  },

  props: {
    start: {
      type: [Date, String],
      default: null,
    },

    end: {
      type: [Date, String],
      default: null,
    },

    minWidth: {
      type: [String, Number],
      default: null,
    },

    index: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      range: {
        start: null,
        end: null,
      },
      show: false,
      attributes: [
        {
          isRange: true,
          highlight: true,
        },
      ],
    };
  },

  computed: {
    isActive() {
      let isActive = false;

      if (this.range.start && this.range.end) {
        isActive = true;
      }

      return isActive;
    },

    minWidthComputed() {
      if (typeof this.minWidth === "number") {
        return `${this.minWidth}px`;
      }
      return this.minWidth;
    },
  },

  created() {
    this.range.start = this.start;
    this.range.end = this.end;
  },

  methods: {
    hideDatePicker() {
      this.show = false;
    },

    resetDates() {
      this.range.start = null;
      this.range.end = null;

      this.emitDates();
    },

    emitDates() {
      if (this.range.start) {
        const startTime = new Date(this.range.start);
        startTime.setHours(0);
        startTime.setMinutes(0);
        startTime.setSeconds(0);

        this.range.start = startTime;
      }

      if (this.range.end) {
        const endTime = new Date(this.range.end);
        endTime.setHours(23);
        endTime.setMinutes(59);
        endTime.setSeconds(59);
        this.range.end = endTime;
      }

      this.$emit("toggle", {
        prop: "date",
        item: {
          start: this.range.start,
          end: this.range.end,
          index: this.index,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors";

.calendar {
  display: flex;
  justify-content: flex-start;

  .button:not(.calendar__reset) {
    width: 100%;
    justify-content: flex-start;

    span {
      white-space: nowrap;
    }

    .icon {
      margin-right: 0.5rem;
    }

    span:nth-child(3) {
      margin: 0 0.25rem;
      display: inline-flex;
    }
  }
}

.calendar--active {
  .button {
    color: color(cta, primary);
  }

  .icon {
    color: color(cta, primary);
  }

  &:hover {
    .button,
    .icon {
      color: color(black, base);
    }

    .calendar__reset {
      display: flex;
    }

    .calendar__icon {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.calendar__picker {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 7000;
}

.calendar__reset {
  position: absolute;
  padding: 0;
  display: none;
  top: 0;
  left: 0;
  z-index: 8000;
}
</style>
